export enum StoreKeys {
  ACCESS_TOKEN_KEY = 'accessToken',
  REFRESH_TOKEN_KEY = 'refreshToken',
  USER_KEY = 'user',
  ROLES = 'roles',
  SUBSCRIPTION_KEY = 'subscriptionKey',
  USER_EMAIL = 'email',
  MESSAGE = 'message',
  CALL_DATA = 'callData',
  CALL_TAB = 'newTab',
  JR_HCP_LOGIN = 'jrHcpLogin',
  LAST_ACTIVITY = 'lastActivity',
  LAST_ROUTE = 'lastRoute',
  TEAMS = 'teams',
  CAL_SYNC = 'calSync',
  CAL_TENANT_CONFIG = 'calTenantConfig',
  CALL_CONNECTED = 'callConnected',
  MA_CALL_DATA = 'maCallData',
  CALL_DURATION = 'callDuration',
  CALL_DECLINED = 'callDeclined',
  MS_TENANTID = 'msTenantId',
  SSO_CONFIG = 'ssoConfig',
  TIME_ZONE = 'timezone',
  CAL_SYNCING = 'isCalSyncing',
  AUDIT_LOG_PDF_STATUS = 'auditLogPDFStatus',
  SCHEDULE_CALL_DATA = 'scheduleCallData',
  ADHOC_CALL_DATA = 'adhocCallData',
  IS_CALL_FROM = 'isCallFrom',
  ADHOC_CALL_REJOIN_DATA = 'adhocCallRejoinData',
  ADHOC_TEAM_CALL_DATA = 'adhocTeamCallData',
  WELCOME_DIALOG = 'welcomeDialog',
  MOD_CALL_MARK_ONLINE_POPUP_DATA = 'modCallMarkOnlinePopupData',
}
